import React, { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PasswordModal from "./PasswordModal";
import PasswordResetForm from "./PasswordResetForm";
import { toast } from "react-toastify";
import { SupabaseContext, supabase } from "./SupabaseContext";

const PasswordResetPage = () => {
  const [email, setEmail] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    
    
  }, [location]);

  useEffect(() => {
    const subscription = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === "PASSWORD_RECOVERY") {
          setShowPasswordModal(true);
        }
      }
    );
    return () => {
      supabase.removeSubscription(subscription);
    };
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault(); // Prevents the form from submitting and refreshing the page

    if (newPassword !== newPasswordConfirm) {
      alert("Passwords do not match");
      return;
    }

    if (newPassword.length < 8) {
      alert("Password should be at least 8 characters");
      return;
    }

    const { data, error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      alert("There was an error updating your password.");
      return;
    }

    if (data) {
      alert("Password updated successfully!");
      setShowPasswordModal(false);
    }
  };

  // Simple email validation function
  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  // Handle password reset request
  const handleReset = async () => {
    if (!isValidEmail(email)) {
      alert("Please enter a valid email address.");
      // toast.error("Invalid Email");
      return;
    }

    try {
      const { data, error } = await supabase.auth.resetPasswordForEmail(email);
      if (data) alert("Password reset email sent.");
      if (error) alert("An error occurred: " + error.message);
    } catch (err) {
      console.error("An error occurred:", err);
    }
  };

  return (
    <>
      <PasswordResetForm
        email={email}
        setEmail={setEmail}
        handleReset={handleReset}
      />

      {showPasswordModal && (
        <PasswordModal
          showPasswordModal={showPasswordModal}
          handlePasswordChange={handlePasswordChange}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          newPasswordConfirm={newPasswordConfirm}
          setNewPasswordConfirm={setNewPasswordConfirm}
        />
      )}
    </>
  );
};

export default PasswordResetPage;
