import React, { useState } from 'react';
import { ChevronRightIcon,ChevronLeftIcon } from "@heroicons/react/24/outline";

const ImageCarouselCard = ({ items, title, description, author, authorImage, date, articleLink, authorLink }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  const renderItem = (item) => {
    if (item.type === 'video') {
      return (<div className="flex justify-center items-center bg-white">
            <video className="object-contain w-full h-auto"  src={item.url} autoPlay muted loop style={{ maxHeight: '600px' }}/>
      </div>)
    } else {
      return <img className="object-contain w-full h-auto" src={item.url} alt="Article" style={{ maxHeight: '600px' }}/>;
    }
  };

  return (
    <div className="overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 relative">
      <button 
        onClick={prevItem} 
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md mx-2"
        
        aria-label="Previous item"
      >

        <ChevronLeftIcon className="h-6 w-6" />
      </button>

      {renderItem(items[currentIndex])}

      <button 
        onClick={nextItem} 
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md mx-2"
        aria-label="Next item"
      >

        <ChevronRightIcon className="h-6 w-6" />
      </button>

      {/* <div className="p-6">
        <div>
          <span className="text-xs font-medium text-blue-600 uppercase dark:text-blue-400">Product</span>
          <a href={articleLink} className="block mt-2 text-xl font-semibold text-gray-800 transition-colors duration-300 transform dark:text-white hover:text-gray-600 hover:underline" tabIndex="0">{title}</a>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{description}</p>
        </div>

        <div className="mt-4">
          <div className="flex items-center">
            <div className="flex items-center">
              <img className="object-cover h-10 rounded-full" src={authorImage} alt="Avatar" />
              <a href={authorLink} className="mx-2 font-semibold text-gray-700 dark:text-gray-200" tabIndex="0">{author}</a>
            </div>
            <span className="mx-1 text-xs text-gray-600 dark:text-gray-300">{date}</span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ImageCarouselCard;
