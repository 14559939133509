// SupabaseContext.js

import React, { createContext } from "react";
import { createClient } from "@supabase/supabase-js";

// Create Supabase client
const supabaseUrl = "https://exrurgzfsodqcwswmabk.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImV4cnVyZ3pmc29kcWN3c3dtYWJrIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTI1MDQ3OTUsImV4cCI6MjAwODA4MDc5NX0.5LHp6H4r994eySUcA57_RxmkgGJvvVMdp0bThjGwGVM";
export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Create context
export const SupabaseContext = createContext(supabase);

export async function addEmailToEmailSignups(email) {
  // First, trim the email
  const trimmedEmail = email.trim();

  // Insert into the emailSignups table
  const { data, error } = await supabase
    .from("emailSignup")
    .insert([{ email: trimmedEmail }]);
  alert("Thanks for signing up for email updates!");
  // if (error) alert("An error occurred: " + error.message);
  // Check if the insert operation was successful
  // if (data && !error) {
  //   console.log("Email insertion successful", data);
  //   // Further actions if needed
  // } else {
  //   console.error("Error inserting email:", error);
  //   // Handle the error
  // }
}

export async function getAggregatedPlayData() {
  const { data, error } = await supabase
    .from("aggregated_playdata")
    .select("*");

  if (error) {
    console.error("Error fetching aggregated play data:", error);
    return [];
  }
  return data;
}

export async function getPlayDataWebViewPaginated(
  page = 1,
  recordsPerPage = 100,
  sortCol = "",
  sortDir = true,
  filterCourseName = "",
  filterTournament = "",
  filterDateStart,
  filterDateEnd
) {
  console.log("filterDateStart", filterDateStart);
  // Calculate the offset
  const offset = (page - 1) * recordsPerPage;
  const startRange = (page - 1) * recordsPerPage;
  const endRange = startRange + recordsPerPage;
  // Fetch data with pagination

  // Start building the query
  let query = supabase
    .from("playdata_webview")
    .select("*", { count: "exact" }) // Get the exact count of the records
    .range(startRange, endRange)
    .order(sortCol, { ascending: sortDir })
    .ilike("courseName", `%${filterCourseName}%`)
    .ilike("tournamentName", `%${filterTournament}%`);

  // if (sortCol !== "holeNumber") {
  //   query = query.order(["holeNumber", { ascending: true }]);
  // }
  // if (sortCol !== "eventDateTime") {
  //   query = query.order("eventDateTime", { ascending: true });
  // }

  // Apply date filters if provided
  if (filterDateStart) {
    query = query.gte("eventDateTime", filterDateStart);
  }
  if (filterDateEnd) {
    query = query.lte("eventDateTime", filterDateEnd);
  }

  // Execute the query
  const { data, error, count } = await query;

  if (error) {
    console.error("Error fetching play data:", error);
    return { data: [], error, totalRecords: 0 };
  }
  return { data, totalRecords: count };
}

export async function getPlayDataWebView() {
  const { data, error } = await supabase.from("playdata_webview").select("*");

  if (error) {
    console.error("Error fetching aggregated play data:", error);
    return [];
  }
  return data;
}

export async function getTournamentWebViewPaginated(
  page = 1,
  recordsPerPage = 100,
  sortCol = "",
  sortDir = true,
  filterTournament = "",
  filterDateStart,
  filterDateEnd
) {
  // Calculate the offset
  const offset = (page - 1) * recordsPerPage;
  const startRange = (page - 1) * recordsPerPage;
  const endRange = startRange + recordsPerPage;
  // Fetch data with pagination

  // Start building the query
  let query = supabase
    .from("aggregated_tournamentdata")
    .select("*", { count: "exact" }) // Get the exact count of the records
    .range(startRange, endRange)
    .order(sortCol, { ascending: sortDir })
    .ilike("tournamentName", `%${filterTournament}%`);

  // Apply date filters if provided
  if (filterDateStart) {
    query = query.gte("min_date", filterDateStart);
  }
  if (filterDateEnd) {
    query = query.lte("min_date", filterDateEnd);
  }

  // Execute the query
  const { data, error, count } = await query;

  if (error) {
    console.error("Error fetching play data:", error);
    return { data: [], error, totalRecords: 0 };
  }
  return { data, totalRecords: count };
}

export async function getPlayDataWebViewCoursePaginated(
  page = 1,
  recordsPerPage = 100,
  sortCol = "",
  sortDir = true,
  filterCourseName = ""
) {
  // Calculate the offset
  const startRange = (page - 1) * recordsPerPage;
  const endRange = startRange + recordsPerPage;
  // Fetch data with pagination

  // Start building the query
  let query = supabase
    .from("playdata_webview_course")
    .select("*", { count: "exact" }) // Get the exact count of the records
    .range(startRange, endRange)
    .order(sortCol, { ascending: sortDir })
    .ilike("courseName", `%${filterCourseName}%`);

  // Execute the query
  const { data, error, count } = await query;

  if (error) {
    console.error("Error fetching play data:", error);
    return { data: [], error, totalRecords: 0 };
  }
  return { data, totalRecords: count };
}

export async function getPlayDataWebViewCourseHolePaginated(
  page = 1,
  recordsPerPage = 100,
  sortCol = "",
  sortDir = true,
  filterCourseName = ""
) {
  // Calculate the offset
  const startRange = (page - 1) * recordsPerPage;
  const endRange = startRange + recordsPerPage;
  // Fetch data with pagination

  // Start building the query
  let query = supabase
    .from("playdata_webview_coursehole")
    .select("*", { count: "exact" }) // Get the exact count of the records
    .range(startRange, endRange)
    .order(sortCol, { ascending: sortDir })
    .order("holeNumberComp", { ascending: true })
    .ilike("courseName", `%${filterCourseName}%`);

  // Execute the query
  const { data, error, count } = await query;

  if (error) {
    console.error("Error fetching play data:", error);
    return { data: [], error, totalRecords: 0 };
  }
  return { data, totalRecords: count };
}
