import { useEffect } from "react";

const PageTitle = ({ pageTitle }) => {
  useEffect(() => {
    document.title = pageTitle;
  }, []);
  return null;
};

export default PageTitle;
