import React, { useState } from 'react';
import ByCourseHoleTab from './ByCourseHoleTab';
import ByCourseTab from './ByCourseTab';
import ByTournamentTab from './ByTournamentTab';

const TabsComponent = () => {
    const [activeTab, setActiveTab] = useState('byCourseHole');

    return (
        <div >
            <div className="text-center">
                <div className="inline-block border-b border-gray-200 dark:border-gray-700">
                    <button
                        className={`inline-flex items-center h-10 px-4 -mb-px text-sm ${
                            activeTab === 'byCourseHoleTab' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'
                        } bg-transparent border-b-2 sm:text-base dark:text-white focus:outline-none hover:border-gray-400`}
                        onClick={() => setActiveTab('byCourseHole')}
                    >
                        By Hole
                    </button>

                    <button
                        className={`inline-flex items-center h-10 px-4 -mb-px text-sm ${
                            activeTab === 'byCourse' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'
                        } bg-transparent border-b-2 sm:text-base dark:text-white focus:outline-none hover:border-gray-400`}
                        onClick={() => setActiveTab('byCourse')}
                    >
                        By Course
                    </button>

                    <button
                        className={`inline-flex items-center h-10 px-4 -mb-px text-sm ${
                            activeTab === 'byTournament' ? 'text-blue-600 border-blue-500' : 'text-gray-700 border-transparent'
                        } bg-transparent border-b-2 sm:text-base dark:text-white focus:outline-none hover:border-gray-400`}
                        onClick={() => setActiveTab('byTournament')}
                    >
                        By Tournament
                    </button>
                </div>

                <div className="tab-content mt-4">
                    {activeTab === 'byCourseHole' ? (
                        <div>
                            {
                                <ByCourseHoleTab />
                            }
                        </div>
                    ) : (
                        <></>
                    )}
                    {activeTab === 'byCourse' ? (
                        <div>
                            {
                                <ByCourseTab />
                            }
                        </div>
                    ) : (
                        <></>
                    )}
                    {activeTab === 'byTournament' ? (
                        <div>
                            <ByTournamentTab />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TabsComponent;
