import React from "react";

function TermsOfService() {
  return (
    <>
      <body class="bg-gray-100 p-6">
        <div class="container mx-auto bg-white p-8 rounded-lg shadow-md">
          <h1 class="text-2xl font-semibold mb-6">
            Dice Golfing - Terms of Service
          </h1>

          {/* <!-- Section 1: Acceptance of Terms --> */}
          <section class="mb-8">
            <h2 class="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
            <p>
              By accessing or using the App, you acknowledge that you have read,
              understood, and agree to be bound by these Terms, including our
              Privacy Policy, which is incorporated by reference.
            </p>
          </section>

          {/* <!-- Section 2: Changes to Terms --> */}
          <section class="mb-8">
            <h2 class="text-xl font-semibold mb-2">2. Changes to Terms</h2>
            <p>
              We reserve the right to modify these Terms at any time. Any
              changes will be effective immediately upon posting, and your
              continued use of the App will constitute your acceptance of such
              changes. Please review these Terms regularly.
            </p>
          </section>

          {/* <!-- Section 3: License and Access --> */}
          <section class="mb-8">
            <h2 class="text-xl font-semibold mb-2">3. License and Access</h2>
            <p>
              Subject to your compliance with these Terms, we grant you a
              limited, non-exclusive, non-transferable, and revocable license to
              use the App for personal, non-commercial purposes. You may not
              reproduce, distribute, modify, create derivative works of,
              publicly display, or perform the App, except as expressly
              permitted by us.
            </p>
          </section>

          {/* <!-- Section 4: User Content --> */}
          <section class="mb-8">
            <h2 class="text-xl font-semibold mb-2">4. User Content</h2>
            <p>
              You may submit content, including but not limited to text, images,
              and gameplay data, to the App. By submitting content, you grant us
              a worldwide, royalty-free, perpetual, irrevocable, and
              sublicensable right to use, reproduce, adapt, publish, distribute,
              and display that content in any medium.
            </p>
          </section>

          {/* <!-- Section 5: In-App Purchases --> */}
          <section class="mb-8">
            <h2 class="text-xl font-semibold mb-2">5. In-App Purchases</h2>
            <p>
              The App may offer in-app purchases. By making in-app purchases,
              you agree to pay all associated charges and fees. In-app purchases
              are non-refundable.
            </p>
          </section>

          {/* <!-- Section 6: Termination --> */}
          <section class="mb-8">
            <h2 class="text-xl font-semibold mb-2">6. Termination</h2>
            <p>
              We reserve the right to terminate or suspend your access to the
              App at our discretion, without notice, for any reason, including a
              violation of these Terms.
            </p>
          </section>

          {/* <!-- Section 7: Privacy --> */}
          <section class="mb-8">
            <h2 class="text-xl font-semibold mb-2">7. Privacy</h2>
            <p>
              Your use of the App is also governed by our Privacy Policy, which
              can be found at our
              <a
                href="https://dicegolfing.com/privacy-policy"
                class="text-blue-500 hover:underline"
              >
                Privacy Policy
              </a>
              . Please review the Privacy Policy to understand how we collect,
              use, and protect your personal information.
            </p>
          </section>

          {/* <!-- Section 8: Disclaimers and Limitation of Liability --> */}
          <section class="mb-8">
            <h2 class="text-xl font-semibold mb-2">
              8. Disclaimers and Limitation of Liability
            </h2>
            <p>
              The App is provided "as is" without warranties of any kind, and we
              do not guarantee its accuracy, reliability, or availability. To
              the extent permitted by law, we shall not be liable for any
              indirect, incidental, special, or consequential damages arising
              out of or in connection with your use of the App.
            </p>
          </section>

          {/* <!-- Section 9: Governing Law --> */}
          <section class="mb-8">
            <h2 class="text-xl font-semibold mb-2">9. Governing Law</h2>
            <p>
              These Terms are governed by and construed in accordance with the
              laws of the Commonwealth of Massachusetts. Any disputes arising
              from or related to these Terms shall be subject to the exclusive
              jurisdiction of the courts in the Commonwealth of Massachusetts.
            </p>
          </section>

          {/* <!-- Section 10: Contact Information --> */}
          <section class="mb-8">
            <h2 class="text-xl font-semibold mb-2">10. Contact Information</h2>
            <p>
              If you have any questions or concerns about these Terms, please
              contact us at{" "}
              <a
                href="mailto:info@dicegolfing.com"
                class="text-blue-500 hover:underline"
              >
                support@dicegolfing.com
              </a>
              .
            </p>
          </section>

          <p class="text-sm">Thank you for using Dice Golfing!</p>
        </div>
      </body>
    </>
  );
}

export default TermsOfService;
