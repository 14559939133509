import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserIcon } from "@heroicons/react/outline";

import NavBarItem from "./navBarItem";
import NavBarDropdown from "./navBarDropdown";

import "./navBar.css";
const logo = require("../media/DiceGolfingBlankLogo_4096.png");
const logofull = require("../media/DiceGolfingLogo.png");

const FooterContainer = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <footer className="bg-gray-100 dark:bg-gray-800">
      <div className="container px-6 py-4 mx-auto">
        <div className="lg:flex">
          <div className="w-full -mx-6 lg:w-2/5">
            <div className="px-6">
              <div>
                <a href="/home">
                  {" "}
                  <img id="footer-logo" src={logo} alt="Logo" />
                </a>
              </div>

              <div className="flex mt-4 -mx-2">
                <a
                  href="http://twitter.com/dicegolfing"
                  className="mx-2 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                  aria-label="Twitter"
                >
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 512 512">
                    <path d="M496,109.5a201.8,201.8,0,0,1-56.55,15.3,97.51,97.51,0,0,0,43.33-53.6,197.74,197.74,0,0,1-62.56,23.5A99.14,99.14,0,0,0,348.31,64c-54.42,0-98.46,43.4-98.46,96.9a93.21,93.21,0,0,0,2.54,22.1,280.7,280.7,0,0,1-203-101.3A95.69,95.69,0,0,0,36,130.4C36,164,53.53,193.7,80,211.1A97.5,97.5,0,0,1,35.22,199v1.2c0,47,34,86.1,79,95a100.76,100.76,0,0,1-25.94,3.4,94.38,94.38,0,0,1-18.51-1.8c12.51,38.5,48.92,66.5,92.05,67.3A199.59,199.59,0,0,1,39.5,405.6,203,203,0,0,1,16,404.2,278.68,278.68,0,0,0,166.74,448c181.36,0,280.44-147.7,280.44-275.8,0-4.2-.11-8.4-.31-12.5A198.48,198.48,0,0,0,496,109.5Z" />
                  </svg>
                </a>
              </div>
              <div className="flex mt-4 -mx-2">
                <a
                  href="https://instagram.com/dicegolfing"
                  className="mx-2 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400"
                  aria-label="instagram"
                >
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 24 24">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div className="mt-6 lg:mt-0 lg:flex-1">
            <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4">
              {/* <div>
                <h3 className="text-gray-700 dark:text-white font-bold">
                  About
                </h3>
                <a
                  href="/about"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  Company
                </a>
              </div>

              <div>
                <h3 className="text-gray-700 dark:text-white font-bold">
                  Blog
                </h3>
                <a
                  href="/blog"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  Latest News
                </a>
              </div> */}

              {/* <div>
                <h3 className="text-gray-700 dark:text-white font-bold">
                  Products
                </h3>
              </div> */}

              <div>
                <h3 className="text-gray-700 dark:text-white font-bold">
                  Contact
                </h3>
                <a
                  href="mailto:info@dicegolfing.com"
                  className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                >
                  info@dicegolfing.com
                </a>
              </div>

              <div>
                <h3 className="text-gray-700 dark:text-white font-bold">
                  About
                </h3>
                <a
                    href="/terms-of-service"
                    className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                  >
                    Terms of Service
                  </a>
                <a
                    href="/privacy-policy"
                    className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                  >
                    Privacy Policy
                  </a>

                
              </div>
            </div>
          </div>
        </div>

        <hr className="h-px my-6 bg-gray-300 border-none dark:bg-gray-700" />


        <div>
          <p className="text-center text-gray-800 dark:text-white">
            © Dice Golfing 2023 - All rights reserved
          </p>
        </div>
        {/* <hr className="h-px my-6 bg-gray-300 border-none dark:bg-gray-700" /> */}
        <div>
          <p className="text-center text-gray-400 dark:text-white text-sm">
          Apple and the Apple logo are trademarks of Apple Inc.
          Google Play and the Google Play logo are trademarks of Google LLC.
          </p>
        </div>

      </div>
    </footer>
  );
};

export default FooterContainer;
