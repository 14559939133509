import React from "react";

function PrivacyPolicy() {
  return (
    <>
      <body class="bg-gray-100 p-6">
        <div class="container mx-auto bg-white p-8 rounded-lg shadow-md">
          <h1 class="text-4xl font-bold mb-4">
            Dice Golfing Mobile Game Privacy Policy
          </h1>

          <p class="mb-4">
            <strong class="font-semibold">Last Updated:</strong> September 18,
            2023
          </p>

          <h2 class="text-2xl font-semibold mb-3">Introduction</h2>
          <p class="mb-4">
            Welcome to Dice Golfing, a mobile game ("we", "our", "us"). We are
            committed to protecting your personal information and your right to
            privacy. If you have any questions or concerns about our policy, or
            our practices with regards to your personal information, please
            contact us at{" "}
            <a
              href="mailto:info@dicegolfing.com"
              class="text-blue-500 hover:underline"
            >
              support@dicegolfing.com
            </a>
            .
          </p>

          <p class="mb-4">
            This Privacy Policy governs the privacy policies and practices of
            our Game. Please read our Privacy Policy carefully as it will help
            you make informed decisions about sharing your personal information
            with us.
          </p>

          <h2 class="text-2xl font-semibold mb-3">Information We Collect</h2>
          <p class="mb-4">We collect the following types of information:</p>

          <h3 class="text-xl font-semibold mb-2">
            Personal Information You Disclose to Us
          </h3>
          <ul class="list-disc list-inside mb-4">
            <li class="mb-2">
              <strong>Usernames and email addresses</strong>: When you register
              for our Game, we collect your chosen username and email address.
            </li>
            <li class="mb-2">
              <strong>Payment Data</strong>: When making in-game purchases,
              payment data is processed by our third-party payment gateway. We
              do not store or collect your payment details.
            </li>
          </ul>

          <h3 class="text-xl font-semibold mb-2">
            Information Automatically Collected
          </h3>
          <ul class="list-disc list-inside mb-4">
            <li class="mb-2">
              <strong>Game Progress and Activity</strong>: We collect
              information about your play history, levels completed, and other
              game-related statistics.
            </li>
            <li class="mb-2">
              <strong>Device Information</strong>: We collect device-specific
              information (such as your hardware model, operating system
              version, unique device identifiers, and mobile network
              information).
            </li>
          </ul>

          <h2 class="text-2xl font-semibold mb-3">Use of Information</h2>
          <p class="mb-4">
            We use the information we collect for various purposes, including:
          </p>
          <ul class="list-disc list-inside mb-4">
            <li class="mb-2">To provide, operate, and maintain our Game</li>
            <li class="mb-2">To manage and remember your game progress</li>
            <li class="mb-2">To manage user accounts</li>
            <li class="mb-2">
              To comply with our legal obligations and resolve any disputes that
              we may have
            </li>
          </ul>

          <h2 class="text-2xl font-semibold mb-3">
            Data Protection Rights under the GDPR
          </h2>
          <p class="mb-4">
            Under the General Data Protection Regulation (GDPR), you have the
            following rights:
          </p>
          <ul class="list-disc list-inside mb-4">
            <li class="mb-2">
              Right of Access: You have the right to access your personal data
              that we hold.
            </li>
            <li class="mb-2">
              Right to Rectification: You have the right to correct any
              inaccuracies in your personal data.
            </li>
            <li class="mb-2">
              Right to Erasure: You have the right to request that we delete
              your personal data.
            </li>
            <li class="mb-2">
              Right to Restrict Processing: You have the right to request that
              we restrict the processing of your personal data.
            </li>
            <li class="mb-2">
              Right to Data Portability: You have the right to transfer your
              personal data to another service or website.
            </li>
          </ul>
          <p class="mb-4">
            If you wish to exercise any of these rights, please contact us at
            support@dicegolfing.com.
          </p>

          <h2 class="text-2xl font-semibold mb-3">Data Retention</h2>
          <p class="mb-4">
            We will retain your personal information for as long as it is
            necessary to fulfill the purposes outlined in this Privacy Policy
            unless a longer retention period is required or permitted by law.
          </p>

          <h2 class="text-2xl font-semibold mb-3">
            Security of Your Information
          </h2>
          <p class="mb-4">
            We use various security measures to protect your information,
            including encryption and secure servers.
          </p>

          <h2 class="text-2xl font-semibold mb-3">Policy for Children</h2>
          <p class="mb-4">
            Our Game is not intended for use by children under the age of 13,
            and we do not knowingly collect any personal information from
            children under the age of 13.
          </p>

          <h2 class="text-2xl font-semibold mb-3">
            Changes to This Privacy Policy
          </h2>
          <p class="mb-4">
            We may change this Privacy Policy from time to time. If we make
            changes, we will notify you by revising the "Last Updated" date at
            the top of this policy and, in some cases, we may provide additional
            notice such as adding a statement to our website homepage or sending
            you a notification.
          </p>

          <h2 class="text-2xl font-semibold mb-3">Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at{" "}
            <a
              href="mailto:info@dicegolfing.com"
              class="text-blue-500 hover:underline"
            >
              support@dicegolfing.com
            </a>
            .
          </p>
        </div>
      </body>
    </>
  );
}

export default PrivacyPolicy;
