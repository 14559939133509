import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PasswordResetRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Parse the URL to extract the reset token or relevant data
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token'); // Adjust the query parameter name based on your URL structure
    console.log("location"+queryParams);
    // Redirect to your password reset route, passing along the token or relevant data
    navigate(`/user?token=${token}`);

  }, [navigate, location]);

  // You can render a loading spinner or a simple message here
  return <div>Redirecting to password reset...</div>;
};

export default PasswordResetRedirect;
