import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "./pageTitle";
import ImageCarouselCard from "./ImageCarouselCard";
import PasswordModal from "./PasswordModal";
import PasswordResetForm from "./PasswordResetForm";
import { toast } from "react-toastify";
import { createClient } from "@supabase/supabase-js";
import { SupabaseContext, supabase, addEmailToEmailSignups } from './SupabaseContext';
// const playvideo = require("../media/diceGolfingShortExample.mp4");
const playvideo2 = require("../media/ActionPreviewnew.mp4");
const playvideo3 = require("../media/DiceGolfingFullPreviewnew.mp4");
const mainmenu = require("../media/homeExport_1290x2796.png");
const tournaments = require("../media/tournamentsExport_1290x2796.png");
const allcourses = require("../media/courses1Export_1290x2796.png");
const gameplay1 = require("../media/gameplay1Export_1290x2796.png");
const gameplay2 = require("../media/gameplay2Export_1290x2796.png");
const gameplay3 = require("../media/gameplayaimingpar4_1290x2796.png");
const gameplay4 = require("../media/gameplaymidpar4_1290x2796.png");
const gameplay5 = require("../media/dunesmidshot_1290x2796.png");
const gameplay6 = require("../media/desertmidshot_1290x2796.png");
const appleDownload = require("../media/apple-badge.png");
const googleDownload = require("../media/google-badge.png");
//https://apps.apple.com/ca/app/dice-golfing/id6466886040
//https://play.google.com/store/apps/details?id=com.dicegolfing.dicegolfing

const Homepage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  useEffect(() => {
    const subscription = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === "PASSWORD_RECOVERY") {
          setShowPasswordModal(true);
        }
      }
    );
    return () => {
      supabase.removeAllChannels();
    };
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault(); // Prevents the form from submitting and refreshing the page

    if (newPassword !== newPasswordConfirm) {
      alert("Passwords do not match");
      return;
    }

    if (newPassword.length < 8) {
      alert("Password should be at least 8 characters");
      return;
    }

    const { data, error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      alert("There was an error updating your password.");
      return;
    }

    if (data) {
      alert("Password updated successfully!");
      setShowPasswordModal(false);
    }
  };

  function isValidEmail(email) {
    // Trim the email to remove whitespace from both ends
    const cleanedEmail = email.trim();

    // Regular expression for validating email
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Test the cleaned email against the regex
    return regex.test(cleanedEmail);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationResult = isValidEmail(username);
    if (validationResult) {
      try {
        try {
          const { data, error } = await addEmailToEmailSignups(username.trim());
          // if (data) alert("Thanks for signing up for email updates!");
          // if (error) alert("An error occurred: " + error.message);
        } catch (err) {
          console.error("An error occurred:", err);
        }

        localStorage.setItem("diceGolfingUsernameUnverified", username);

        // toast.success("Thanks for signing up!")
        // navigate("/register", { replace: true });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // toast.error(error.response.data);
        }
      }
    } else {
      // toast.error("Invalid Email");
      alert("Please enter a valid email address.");
    }
  };

  return (
    <>
      <PageTitle pageTitle="Dice Golfing - Home" />
      {showPasswordModal && (
              <PasswordModal
                showPasswordModal={showPasswordModal}
                handlePasswordChange={handlePasswordChange}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                newPasswordConfirm={newPasswordConfirm}
                setNewPasswordConfirm={setNewPasswordConfirm}
              />
            )}
        <section className="bg-gray-100 dark:bg-gray-900">
          <div className="container px-4 py-2 mx-auto lg:flex lg:items-center lg:justify-between">
            <h4 className=" font-semibold tracking-tight text-gray-800  dark:text-white">
               
            </h4>
            <div className="mt-2 lg:mt-0">
              
              {/* <form onSubmit={handleSubmit}>
                  <input
                    className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md sm:mx-2 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    type="username"
                    placeholder="Email Address"
                    aria-label="Email"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    required
                    autoComplete="on"
                    error={errors["username"]}
                  />
                  <span>
                  <button
                    className="px-5 mx-2 py-2 font-semibold text-white transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
                    type="submit"
                  >
                    Sign-up for News
                  </button>
                  
                    </span>
                  
                  
                </form> */}
                <span className="px-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <a
                  href="https://apps.apple.com/ca/app/dice-golfing/id6466886040"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                  target="_blank"
                >
                    <img className="object-contain px-2" src={appleDownload} alt="Apple" style={{ width: '120px', height: '40px' }}/></a>
                    <a
                  href="https://play.google.com/store/apps/details?id=com.dicegolfing.dicegolfing"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                  target="_blank"
                >
                 <img className="object-contain" src={googleDownload} alt="Google" style={{ width: '120px', height: '40px' }}/>
                </a>
                </span>
              
                
              

                
              
            </div>
            
          </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
        <div className="container px-6 py-6 mx-auto text-center">
        <div className="max-w-lgz mx-auto">
            <h1 className="text-3xl font-semibold text-gray-800 dark:text-white lg:text-4xl">Welcome to Dice Golfing</h1>

            {/* <p className="mt-6 text-gray-500 dark:text-gray-300">
            The classic game of golf reimagined as a casual, fast-paced, dice-rolling mobile game
            </p> */}

            {/* <p className="text-xl font-semibold mt-4 text-gray-500 dark:text-gray-300">Play the demo <a
                  href="/play"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                >
                  here
                </a></p> */}


        </div>

    </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
          <div className="container max-w-4xl px-6 py-10 mx-auto">
            <ImageCarouselCard
                  items={[
                    // { url: playvideo, type: 'video' },
                    { url: playvideo2, type: 'video' },
                    { url: playvideo3, type: 'video' },
                    { url: gameplay1, type: 'image' },
                    { url: mainmenu, type: 'image' },
                    { url: gameplay2, type: 'image' },
                    { url: gameplay3, type: 'image' },
                    { url: gameplay4, type: 'image' },
                    { url: gameplay5, type: 'image' },
                    { url: gameplay6, type: 'image' },
                    // { url: playtype, type: 'image' },
                    { url: tournaments, type: 'image' },
                    { url: allcourses, type: 'image' },
                    // { url: statistics, type: 'image' }
     
                  ]}
                  title="Welcome to Dice Golfing"
                  description="A mobile golf game where you roll dice to get around."
                  author=""
                  authorImage=""
                  date=""
                  articleLink="#"
                  authorLink="#"
                />
          </div>
          
        </section>
        <section>
          <div class="border-2 border-gray-100 rounded-lg dark:border-gray-700 justify-center items-center w-3/4 mx-auto px-6">

            <p className="mt-6 text-gray-700 dark:text-gray-300 text-xl">
            Welcome to Dice Golfing, a unique game combining the fun of golf with the engaging strategy of dice-based board game. Players can explore a variety of carefully designed courses, each offering its own distinct challenge and layout.
            </p>
            <p className="mt-6 text-gray-700 dark:text-gray-300 text-xl">
            In Dice Golfing, your approach to each shot involves strategic planning around the randomness introduced by the dice. There are numerous factors to account for such as: wind, rough, bunkers, trees, water hazards, elevation changes and shot distance. Do you lay up by safely rolling 3 dice, or do you take a gamble rolling 5 dice into the wind, around a tree to muscle a shot on the green? It's a game where thoughtful decision-making meets the excitement of unpredictability.
            </p><p className="mt-6 text-gray-700 dark:text-gray-300 text-xl">
            Want to see how you stack up against others? Join in the fun of daily tournaments, competing with players from around the world  to see who is best in several friendly formats.
            </p><p className="my-6 text-gray-700 dark:text-gray-300 text-xl">
            The game is continually evolving, with new courses to be added regularly ensuring a fresh experience. The blend of beautiful course designs, engaging gameplay, and a balance between skill and luck makes Dice Golfing an enjoyable game for golf fans, board game lovers, and anyone looking for a new twist in their gaming routine.
              </p>
          </div>
        </section>


    </>
  );
};

export default Homepage;
