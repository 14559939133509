import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
// import { Bars3Icon, XIcon } from "@heroicons/react/outline";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { Transition } from "@headlessui/react";

import NavBarItem from "./navBarItem";
import NavBarDropdown from "./navBarDropdown";

import "./navBar.css";
const logo = require("../media/DiceGolfingBlankLogo_4096.png");
const logofull = require("../media/DiceGolfingLogoGrey.png");
const itemStyleName = "text-gray-500 px-3 py-2 rounded-lg text-sm font-medium";

const itemStyleH =
  "text-gray-500 hover:bg-gray-100  px-3 py-2 rounded-lg text-sm font-medium nav-btn z-50";
const itemStyleV =
  "text-gray-500 hover:bg-gray-50  block px-3 py-2 rounded-md text-base font-medium nav-btn z-50";

const NavBar = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const linkList = [
    { key: "link_1", href: "/home", label: "Home" },
    { key: "link_2", href: "/about", label: "About" },
    { key: "link_3", href: "/stats", label: "Stats" },
    { key: "link_4", href: "/play", label: "Play" },
  ];



  return (
    <>
      <nav className="bg-white shadow dark:bg-gray-800">
        <div className="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
          <div className="flex items-center justify-between mx-5 w-full">
            <div className="flex-shrink-0 py-2">
              <a href="/home">
                {" "}
                <img id="navbar-logo" src={logofull} alt="Logo" />
              </a>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-center space-x-4">
                {linkList.map((link) => (
                  <NavBarItem {...link} classStyles={itemStyleH} />
                ))}

              </div>
            </div>
            <div className="mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <Bars3Icon className="h-6 w-6" />
                ) : (
                  <XMarkIcon className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden" id="mobile-menu">
                <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {linkList.map((link) => (
                    <NavBarItem {...link} classStyles={itemStyleV} />
                  ))}



                </div>
              </div>
            )}
          </Transition>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
