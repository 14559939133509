import React from "react";
import { NavLink } from "react-router-dom";

const NavBarItem = (props) => {
  return (
    <React.Fragment>
      <NavLink to={props.href} className={props.classStyles}>
        {props.label}
      </NavLink>
    </React.Fragment>
  );
};

export default NavBarItem;
