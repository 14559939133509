import React from "react";

function PasswordModal({
  showPasswordModal,
  handlePasswordChange,
  newPassword,
  setNewPassword,
  newPasswordConfirm,
  setNewPasswordConfirm,
}) {
  return (
    <>
      {showPasswordModal && (
        <section className="max-w-4xl p-6 mx-auto bg-blue-100 rounded-md shadow-md dark:bg-gray-800">
          <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
            Change Password
          </h2>
          <form onSubmit={handlePasswordChange}>
            <div className="grid grid-cols-1 gap-6 mt-4">
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  htmlFor="newPassword"
                >
                  New Password
                </label>
                <input
                  id="newPassword"
                  type="password"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div>
                <label
                  className="text-gray-700 dark:text-gray-200"
                  htmlFor="newPassword"
                >
                  Confirm Password
                </label>
                <input
                  id="newPasswordConfirm"
                  type="password"
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  value={newPasswordConfirm}
                  onChange={(e) => setNewPasswordConfirm(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-end mt-6">
              <button
                className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                // onClick={handlePasswordChange}
              >
                Change Password
              </button>
            </div>
          </form>
        </section>
      )}
    </>
  );
}

export default PasswordModal;
