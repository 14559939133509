import React, { useState } from "react";
import PageTitle from "./pageTitle";
import Accordion from "./accordion";
// import { CheckCircleIcon } from "@heroicons/react/24/outline";
const appleDownload = require("../media/apple-badge.png");
const googleDownload = require("../media/google-badge.png");

const Play = () => {
  return (
    <>
      <PageTitle pageTitle="Dice Golfing - Play" />
      <section class="bg-white dark:bg-gray-900">
    <div class="container max-w-4xl px-6 py-10 mx-auto">
        <h1 class="text-2xl font-semibold text-center text-gray-800 lg:text-3xl dark:text-white">Play Now</h1>

        <div class="mt-12 space-y-8">


            <div class="border-2 border-gray-100 rounded-lg dark:border-gray-700">
            <h1 class="text-xl font-semibold text-gray-700 dark:text-white px-8 py-4">Download the latest versions for Apple and Android below: </h1>



              <hr class="border-gray-200 dark:border-gray-700" />

              <p class="p-8 text-sm text-gray-500 dark:text-gray-300">


              <div className="flex justify-center items-center">
                
              <h3 class="text-gray-900 mb-2"> 
                 <a
                  href="https://apps.apple.com/ca/app/dice-golfing/id6466886040"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                  target="_blank"
                >
      
                    <img className="object-contain px-2 items-start" src={appleDownload} alt="Apple" style={{ width: '120px', height: '40px' }}/>
                    
                
                </a></h3>
                <h3 class="text-gray-900 mb-2"> 
                 <a
                  href="https://play.google.com/store/apps/details?id=com.dicegolfing.dicegolfing"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                  target="_blank"
                >
                 <img className="object-contain" src={googleDownload} alt="Google" style={{ width: '120px', height: '40px' }}/>
                </a></h3>
              </div>      



              </p>
            </div>

        </div>
    </div>
</section>
    </>
  );
};

export default Play;
