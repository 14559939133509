import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "./pageTitle";
import { toast } from "react-toastify";
import TabsComponent from './TabsComponent';


const Statistics = () => {


  return (
    <>
      <PageTitle pageTitle="Dice Golfing - Statistics" />

      <div className="flex justify-center">
          <div className="max-w-lg mx-auto text-center">
              <h1 className="text-3xl font-semibold text-gray-800 dark:text-white lg:text-4xl">Statistics</h1>
          </div>
      </div>


        <TabsComponent />

    </>
  );
};

export default Statistics;
