// PageViewTracker.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const PageViewTracker = () => {
  const location = useLocation();

  console.log(location);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return null; // This component does not render anything
};

export default PageViewTracker;
