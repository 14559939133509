import React, { useEffect, useState, useContext } from 'react';
import {
  useReactTable,
  ColumnSizing,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import { SupabaseContext, getPlayDataWebViewCoursePaginated } from './SupabaseContext';
import '../index.css';

const ByCourseTab = () => {
  const [data, setData] = useState([]);
  const supabase = useContext(SupabaseContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(500);
  const [recordsPerPage, setRecordsPerPage] = useState(25);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortCol, setSortCol] = useState("courseName");
  const [lastSortCol, setLastSortCol] = useState("courseName");
  const [sortDir, setSortDir] = useState(1);
  const [filterCourseName, setFilterCourseName] = useState("");


  useEffect(() => {
    const sortDirString = sortDir>0 ? true : false;
    const fetchData = async () => {
      const { data, totalRecords } = await getPlayDataWebViewCoursePaginated(currentPage, recordsPerPage, sortCol, sortDirString, filterCourseName);
      const calculatedLastPage = Math.ceil(totalRecords / recordsPerPage);
      setLastPage(calculatedLastPage);
      setData(data);
    };

    fetchData();
  }, [supabase, currentPage, recordsPerPage, sortCol, sortDir, filterCourseName]);

  useEffect(() => {
    setLastSortCol(prev => sortCol);

  }, [sortCol])

  const handleHeaderClick = (column) => {
    if (column.id == sortCol)
    {
        setSortDir(prev => prev * -1);
    }

    if (column.id) {
      setSortCol(column.id);
    }
  };

    const columns = React.useMemo(
    () => [
        { header: () => 'Course', accessorKey: 'courseName' },
        { header: () => 'Distance', accessorKey: 'totaldist' },
        { header: () => 'Par', accessorKey: 'totalpar' },
        { header: () => 'Avg. Score', accessorKey: 'avg_score' },
        { header: () => 'Avg. Score To Par', accessorKey: 'avg_scoretopar' },
        { header: () => 'Avg. # Putts', accessorKey: 'avg_numberofputts' },
        { header: () => 'Avg. Putt Dist.', accessorKey: 'avg_puttdist' },
        { header: () => 'Avg. Driving Distance', accessorKey: 'avg_drivingdistance' },
        { header: () => 'Avg. Fairway In Reg.', accessorKey: 'avg_fairwayinreg' },
        { header: () => 'Avg. Green In Reg.', accessorKey: 'avg_greeninreg' },
        { header: () => 'Avg. Sand Save', accessorKey: 'avg_sandsave' },
        { header: () => 'Avg. Tree Hit', accessorKey: 'avg_treehit' },
        { header: () => 'Avg. Water Ball', accessorKey: 'avg_waterball' },
        { header: () => 'Avg. Out of Bounds', accessorKey: 'avg_oob' },

    ],
    []
    );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  

  return (
    <div className="p-2 overflow-x-auto">
        {/* Search Container */}
        <div className="flex items-center mb-4">
            <label htmlFor="courseSearch" className="mr-2 font-xl text-gray-700">Course Name</label>
            <input
                id="courseSearch"
                type="text"
                placeholder="Search by Course Name..."
                value={filterCourseName}
                onChange={(e) => setFilterCourseName(e.target.value)}
                className="px-4 py-2 border rounded-md"
            />
        </div>

      <div className="overflow-x-auto table-container">
        <table {...{
            style: {
              width: table.getCenterTotalSize(),
            },
          }}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                  className="cursor-pointer"
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize() }}
                  
                >
                    <div onClick={() => handleHeaderClick(header.column)}>

                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {sortCol === header.column.id && (
                        <span className="absolute right-2 text-blue-500" >
                        {sortDir === 1 ? "↑" : "↓"}
                        </span>
                    )}
                    </div>
                    <div
                      {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${
                          header.column.getIsResizing() ? 'isResizing' : ''
                        }`,
                        style: {
                            transform:
                              
                              header.column.getIsResizing()
                                ? `translateX(${
                                    table.getState().columnSizingInfo.deltaOffset
                                  }px)`
                                : '',
                          },
                      }}
                    />

                    
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td
                    {...{
                      key: cell.id,
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination and other controls */}
      <div className="flex items-center justify-center space-x-1">

        {/* Previous button */}
        <button 
            onClick={() => setCurrentPage(currentPage - 1)} 
            disabled={currentPage === 1}
            className="px-4 py-2 mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-md dark:bg-gray-800 dark:text-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-white dark:hover:text-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
            {/* Previous */}
            &#8592;
        </button>

        {/* First button */}
        <button 
            onClick={() => setCurrentPage(1)} 
            disabled={currentPage === 1}
            className="px-4 py-2 mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-md dark:bg-gray-800 dark:text-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-white dark:hover:text-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
            1
        </button>

        {/* Last button */}
        <button 
            disabled
            className="px-4 py-2 mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-md dark:bg-gray-800 dark:text-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
            ...
        </button>

        {/* Last button */}
        <button 
            onClick={() => setCurrentPage(lastPage)} 
            disabled={currentPage === lastPage}
            className="px-4 py-2 mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-md dark:bg-gray-800 dark:text-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-white dark:hover:text-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
            {lastPage}
        </button>
        {/* Next button */}
        <button 
            onClick={() => setCurrentPage(currentPage + 1)} 
            disabled={currentPage === lastPage}
            className="px-4 py-2 mx-1 text-gray-700 transition-colors duration-300 transform bg-white rounded-md dark:bg-gray-800 dark:text-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-white dark:hover:text-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
            &#8594;
        </button>


        {/* Current page indicator */}
        <span className="px-4 py-2 mx-1 text-gray-700 bg-white rounded-md dark:bg-gray-800 dark:text-gray-200">
            Page {currentPage} of {lastPage}
        </span>

        {/* Records per page selector */}
        <select 
            value={recordsPerPage} 
            onChange={(e) => setRecordsPerPage(Number(e.target.value))}
            className="px-4 py-2 mx-1 text-gray-700 bg-white rounded-md dark:bg-gray-800 dark:text-gray-200"
        >
            <option value={25}>25</option>
            <option value={100}>100</option>
            <option value={250}>250</option>
        </select>
    </div>
    </div>
  );
};

export default ByCourseTab;
