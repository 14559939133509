import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PageViewTracker from "./PageViewTracker"; // New component for tracking
import ReactGA from "react-ga4";
import Homepage from "./components/Homepage";
import Statistics from "./components/Statistics";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import PasswordResetPage from "./components/PasswordResetPage";
import Layout from "./components/layout";
import NotFound from "./components/notFound";
import About from "./components/about";
import Play from "./components/play";
import DeleteAccount from "./components/deleteAccount";
import PasswordResetRedirect from "./components/PasswordResetRedirect";

const RouterWithGA4 = () => {
  return (
    <>
      <Router>
        <PageViewTracker /> {/* Place the tracker at the top of your Router */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/home" element={<Homepage />} />
            <Route path="/#" element={<PasswordResetRedirect />} />
            <Route path="/password-reset" element={<PasswordResetPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/about" element={<About />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/stats" element={<Statistics />} />
            <Route path="/play" element={<Play />} />
            <Route index element={<Homepage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default RouterWithGA4;
