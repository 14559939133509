import React, { useState } from "react";
import PageTitle from "./pageTitle";
import Accordion from "./accordion";
// import { CheckCircleIcon } from "@heroicons/react/24/outline";

const About = () => {
  return (
    <>
      <PageTitle pageTitle="Dice Golfing - About" />
      <section class="bg-white dark:bg-gray-900">
    <div class="container max-w-4xl px-6 py-10 mx-auto">
        <h1 class="text-2xl font-semibold text-center text-gray-800 lg:text-3xl dark:text-white">About</h1>

        <div class="mt-12 space-y-8">
            <div class="border-2 border-gray-100 rounded-lg dark:border-gray-700">

                <h1 class="text-xl font-semibold text-gray-700 dark:text-white px-8 py-4">Welcome to <span class="font-semibold">Dice Golfing</span>, a mobile golf game! </h1>



                <hr class="border-gray-200 dark:border-gray-700" />

                <p class="p-8 text-sm text-gray-500 dark:text-gray-300">


                      
                      {/* <h3 class="text-lg font-semibold text-gray-900 mb-2">Dice Golfing reimagines the classic game of golf as a fast-paced, dice-rolling board game</h3> */}
                      <ul class="list-disc pl-5 mb-4 text-gray-700">
                          <li><strong>Roll the Dice:</strong> Every stroke is determined by the roll of dice, adding a layer of strategy and unpredictability to each shot.</li>
                          <li><strong>Curve Shots Around Trouble:</strong> Draw / Fade your way around obstacles while trying not to hook / slice your ball out-of-bounds</li>
                          <li><strong>Challenging Terrain:</strong> Navigate your way through rough patches, dense trees, tricky bunkers, and dynamic wind conditions.</li>
                          <li><strong>Elevation Changes:</strong> Experience the ups and downs of a real golf course with elevation changes that affect your gameplay.</li>
                          <li><strong>Accessible Anywhere:</strong> Whether you're a seasoned golfer or have never picked up a club, Dice Golfing offers an easy-to-learn, yet deeply engaging experience right at your fingertips.</li>
                      </ul>


                </p>
            </div>

            <div class="border-2 border-gray-100 rounded-lg dark:border-gray-700">
            <h1 class="text-xl font-semibold text-gray-700 dark:text-white px-8 py-4">Where can I download the game? </h1>



              <hr class="border-gray-200 dark:border-gray-700" />

              <p class="p-8 text-sm text-gray-500 dark:text-gray-300">


                    
                <h3 class="text-gray-900 mb-2">Currently in open-testing with anticipated launches on both iOS and Android soon. Want to help test? Play the 
                 <a
                  href="/play"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                >
                   {" "}Demo Version here
                </a>.</h3>



              </p>
            </div>

        </div>
    </div>
</section>
    </>
  );
};

export default About;
