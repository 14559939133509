import React, { useState } from "react";
import PageTitle from "./pageTitle";
import Accordion from "./accordion";
// import { CheckCircleIcon } from "@heroicons/react/24/outline";

const DeleteAccount = () => {
  return (
    <>
      <PageTitle pageTitle="Dice Golfing - About" />
      <section class="bg-white dark:bg-gray-900">
    <div class="container max-w-4xl px-6 py-10 mx-auto">
        <h1 class="text-2xl font-semibold text-center text-gray-800 lg:text-3xl dark:text-white">Deleting Accounts</h1>

        <div class="mt-12 space-y-8">

            <div class="border-2 border-gray-100 rounded-lg dark:border-gray-700">
            <h1 class="text-xl font-semibold text-gray-700 dark:text-white px-8 py-4">Delete Your Account </h1>



              <hr class="border-gray-200 dark:border-gray-700" />

              <p class="p-8 text-sm text-gray-500 dark:text-gray-300">


                    
                    <h3 class="text-gray-900 mb-2">You can either delete your account via the app by going to Settings -> Delete Account and entering your password, or by sending a request to the following email <a
                  href="mailto:info@dicegolfing.com"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                >
                  info@dicegolfing.com
                </a>.</h3>



              </p>
            </div>

        </div>
    </div>
</section>
    </>
  );
};

export default DeleteAccount;
