import React from "react";
import PageTitle from "./components/pageTitle";
import RouterWithGA4 from "./RouterWithGA4";

function App() {
  return (
    <>
      <PageTitle pageTitle="Dice Golfing" />
      <RouterWithGA4 />
    </>
  );
}

export default App;

// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Homepage from "./components/Homepage";
// import PrivacyPolicy from "./components/PrivacyPolicy";
// import TermsOfService from "./components/TermsOfService";
// import PasswordResetPage from "./components/PasswordResetPage";
// import PageTitle from "./components/pageTitle";
// import Layout from "./components/layout";
// import NotFound from "./components/notFound";
// import About from "./components/about";
// import DeleteAccount from "./components/deleteAccount";
// import ReactGA from "react-ga4";

// ReactGA.initialize("G-KR97KHD7SY");

// function App() {
//   return (
//     <>
//       <PageTitle pageTitle="Dice Golfing" />
//       <Router>
//         <Routes>
//           <Route path="/" element={<Layout />}>
//             <Route path="/home" element={<Homepage />} />
//             <Route path="/password-reset" element={<PasswordResetPage />} />
//             <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//             <Route path="/terms-of-service" element={<TermsOfService />} />
//             <Route path="/about" element={<About />} />
//             <Route path="/delete-account" element={<DeleteAccount />} />
//             <Route path="/" element={<Homepage />} />
//             <Route path="*" element={<NotFound />} />
//           </Route>
//         </Routes>
//       </Router>
//     </>
//   );
// }

// export default App;
