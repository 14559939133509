import { Outlet } from "react-router-dom";
import NavBar from "./navBar";
import FooterContainer from "./footer";

const Layout = () => {
  return (
    <>
      <div className="App flex flex-col h-screen">
        <NavBar />

        <div className="flex-grow">
          <Outlet />
        </div>

        <FooterContainer />
      </div>
    </>
  );
};

export default Layout;
